<template>
	<div>
		<footer class="footer-area">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-md-6">
						<div class="single-footer-widget">
							<div class="logo">
								<router-link to="/"
									><img
										class="footer-logo"
										src="../../assets/images/bankeasy-dark.png"
										alt="logo"
								/></router-link>
								<p>
									We provide banking services for entrepreneurs, access to technology,
									tools, and insights to build, and grow your own financial services
								</p>
							</div>

							<ul class="social-links">
								<!-- <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li> -->
								<li>
									<a
										href="https://twitter.com/bankeasyng?s=21&t=Nipc7fzPdShNiqyHywWM8A"
										target="_blank"
										><i class="fab fa-twitter"></i
									></a>
								</li>
								<li>
									<a
										href="https://instagram.com/bankeasyng?igshid=YmMyMTA2M2Y="
										target="_blank"
										><i class="fab fa-instagram"></i
									></a>
								</li>
								<!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
							</ul>
						</div>
					</div>

					<div class="col-lg-4 col-sm-6 col-md-6">
						<div class="single-footer-widget pl-5">
							<h3>Our Services</h3>

							<ul class="list">
								<li>Revenue Collection</li>
								<li>Card Issuance</li>
								<li>Account Opening</li>
								<li>Loan Services</li>
								<li>Instant Deposits</li>
							</ul>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 col-md-6">
						<div class="single-footer-widget">
							<h3>Support</h3>

							<ul class="list">
								<!-- <li><router-link to="/faq">FAQ's</router-link></li> -->
								<li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
								<li><router-link to="/terms-condition">Terms & Condition</router-link></li>
								<!-- <li><router-link to="/#">Community</router-link></li> -->
								<!-- <li><router-link to="/contact">Contact Us</router-link></li> -->
							</ul>
						</div>
					</div>

					<div class="col-lg-4 col-sm-6 col-md-6">
						<div class="single-footer-widget">
							<h3>Contact Info</h3>

							<ul class="footer-contact-info">
								<li>
									<span>Location:</span> Abuja office: 36, Nouckachott Avenue, Zone 1, Wuse,
									Abuja.
								</li>
								<li>
									<span>Email:</span>
									<a href="mailto:Projects@bankeasyng.com"> Projects@bankeasyng.com</a>
								</li>
								<li><span>Phone:</span> <a href="tel:+321984754">+2348033445507</a></li>
								<li><span>Office Hours:</span> Monday - Friday 9AM - 5PM</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="copyright-area">
					<p>
						Copyright @2022 BankEasy. All rights reserved
						<a href="https://smadeandSmight.com" target="_blank">SmadeandSmight.com</a>
					</p>
				</div>
			</div>

			<div class="map-image"><img src="../../assets/images/map.png" alt="map" /></div>
		</footer>
		<!-- End Footer Area -->

		<back-to-top bottom="50px" right="50px">
			<div class="go-top">
				<i class="fas fa-arrow-up"></i>
			</div>
		</back-to-top>
	</div>
</template>

<script>
export default {
	name: "Footer",
};
</script>
